import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
export const configJSON = require("../../blocks/landingpage/src/config");

const dashboardSvg = require('./dashboard.svg');
const propertiesSvg = require('./properties.svg');
const reportSvg = require('./reports.svg');
const settingSvg = require('./settings.svg');
const supportSvg = require('./support.svg');
export default class AdminSideBar extends Component {
  
  render() {
    return (
      <Box sx={styles.sideBarConatiner}>
        <Box sx={styles.sideBarWrapper}>
          <Box sx={styles.listContainer}>
            <Link to="/" style={styles.listItem}>
              <img src={dashboardSvg} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography style={styles.lisgItemText}>Dashboard</Typography>
            </Link>
            <Link to="/PropertiesPage" style={styles.activeListItem}>
              <img src={propertiesSvg} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography style={styles.lisgItemText}>Properties</Typography>
            </Link>
            <Link to="/" style={styles.listItem}>
              <img src={reportSvg} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography style={styles.lisgItemText}>Reports</Typography>
            </Link>
            <Link to="/" style={styles.listItem}>
              <img src={settingSvg} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography style={styles.lisgItemText}>Settings</Typography>
            </Link>
            <Link to="/" style={styles.listItem}>
              <img src={supportSvg} alt="Logo" style={{ height: '24px', margin: "8px" }} />
              <Typography style={styles.lisgItemText}>Support</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }
}
const styles = {
  sideBarConatiner: {
    position: "relative",
    width: "20%",
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  sideBarWrapper: {

  },
  listContainer: {
    padding: "20px",
  },
  listItem: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    backgroundColor:  "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",
  },
  activeListItem: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    backgroundColor:  "#E8F9FC",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",
  },
  lisgItemText: {
    alignItems: "center",
    flex: 1,
    display: "flex",
  },
};
